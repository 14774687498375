<template>
  <div v-if="dataProps.sort" class="table-all">
    <DataTable
      data-name="table-all"
      class="p-datatable-sm"
      stripedRows
      showGridlines
      selectionMode="multiple"
      :value="array"
      ref="dt"
      :lazy="true"
      :paginator="dataProps.isPaginator"
      :rows="dataProps.rows"
      :totalRecords="dataProps.total"
      :loading="dataProps.loading"
      @page="onPage($event, 'global')"
      responsiveLayout="scroll"
      :scrollable="true"
      sortMode="single"
      breakpoint="1360px"
      @sort="onSort($event)"
      v-model:filters="filters1"
      filterDisplay="menu"
      resizableColumns="true"
      columnResizeMode="expand"
      :rowClass="rowClass"
    >
      <template #empty>
        <div class="text-center w-full text-sm">
          Транзакции не найдены. Попробуйте изменить условия запроса.
        </div>
      </template>
      <template #loading>
        <b style="color: #fff"
          >Загрузка данных о транзакциях. Пожалуйста подождите.</b
        >
      </template>
      <Column field="row_number" style="max-width: 3rem"> </Column>
      <Column
        field="card_number"
        :showFilterMenu="false"
        style="min-width: 10rem"
        :sortable="dataProps.sort"
        header="Номер карты"
      >
        <template #header>
          <FilterSearch
            type="input"
            v-model="dataProps.filters.cardNumber"
            :placeholderName="'Номер карты'"
          />
        </template>

        <template #body="slotProps">
          <div style="min-width: 90px !important; overflow: hidden">
            <img
              style="margin-right: 1rem; width: 1.5rem"
              :src="
                getCardDesign(
                  slotProps.data.card_type,
                  slotProps.data.handler_code,
                  'cardIcon'
                )
              "
            />
            <span v-if="slotProps.data.card_number">
              {{ slotProps.data.card_number }}</span
            >

            <span v-else><b>Общий итог:</b></span>
          </div>
        </template>
      </Column>
      <Column field="holder" header="Держатель" :sortable="dataProps.sort">
        <template #header>
          <FilterSearch
            type="input"
            v-model="dataProps.filters.holder"
            :placeholderName="'Держатель'"
          />
        </template>
        <template #body="slotProps">
          <div>
            {{ slotProps.data.holder ? slotProps.data.holder.name : null }}
          </div>
        </template>
      </Column>

      <Column field="date" header="Дата" :sortable="dataProps.sort">
        <template #body="slotProps">
          <div
            v-tooltip.bottom="
              slotProps.data.date ? formaterDate(slotProps.data.date) : ''
            "
          >
            {{ slotProps.data.date ? formaterDate(slotProps.data.date) : "" }}
          </div>
        </template>
      </Column>

      <Column field="company_name" header="Компания" :sortable="dataProps.sort">
        <template #body="slotProps">
          <div v-tooltip.bottom="slotProps.data.company_name">
            {{ slotProps.data.company_name }}
          </div>
        </template>
      </Column>
      <Column
        field="card_group"
        bodyClass=""
        header="Группа карт"
        :sortable="dataProps.sort"
      >
        <template #header>
          <FilterSearch
            type="input"
            v-model="dataProps.filters.card_group"
            :placeholderName="'Группа карт'"
          />
        </template>

        <template #body="slotProps">
          <div>
            {{
              slotProps.data.card_group ? slotProps.data.card_group.name : ""
            }}
          </div>
        </template>
      </Column>
      <Column field="to_address" header="Адрес" :sortable="dataProps.sort">
        <template #header>
          <FilterSearch
            type="input"
            v-model="dataProps.filters.to_address"
            :placeholderName="'Введите адрес'"
          />
        </template>
      </Column>
      <div v-if="$store.state.curUser">
        <div v-if="$store.state.curUser.role == 1">
          <Column
            field="handler_name"
            :showFilterMenu="false"
            header="Обработчик"
          >
            <template #header>
              <FiltersSelect
                :items="dataProps.handlersList"
                :name="'name'"
                v-model="dataProps.filters.handlers"
              />
            </template>
          </Column>
        </div>
      </div>
      <Column
        :showFilterMenu="false"
        :sortable="dataProps.sort"
        v-for="(col, index) of dataProps.columns"
        :field="col.field"
        :key="col.field + '_' + index"
        :header="col.header"
      >
        <template #header>
          <component
            type="input"
            v-model="dataProps.filters[col.field]"
            :placeholderName="col.placeholder"
            :dsc="col.dsc"
            :is="col.filters"
          ></component>
        </template>
      </Column>
      <Column
        field="service_name"
        :showFilterMenu="false"
        :sortable="dataProps.sort"
        header="Услуга"
      >
        <template #header>
          <FilterSearch
            type="input"
            :dsc="true"
            v-model="dataProps.filters.service"
            :placeholderName="'Услуга'"
          /> </template
      ></Column>
      <Column
        field="amount"
        header="Кол-во, л"
        :showFilterMenu="false"
        :sortable="dataProps.sort"
      >
        <template #header>
          <FilterFromTo
            v-model:valueFrom="dataProps.filters.amount.from"
            v-model:valueTo="dataProps.filters.amount.to"
          />
        </template>
        <template #body="slotProps">
          <span
            :class="{
              'font-bold':
                this.array.indexOf(slotProps.data) === this.array.length - 1,
            }"
          >
            {{ slotProps.data.amount }}
          </span>
        </template>
      </Column>

      <div v-if="$store.state.curUser">
        <div v-if="!$store.state.curUser.hide_price">
          <Column field="price_to" header="Цена ТО, Р"></Column>
        </div>
      </div>
      <Column
        field="price"
        header="Цена, Р"
        :showFilterMenu="false"
        :sortable="dataProps.sort"
      >
        <template #header>
          <FilterFromTo
            v-model:valueFrom="dataProps.filters.price.from"
            v-model:valueTo="dataProps.filters.price.to"
          />
        </template>
        <template #body="slotProps">
          <div v-if="!slotProps.data.average">{{ slotProps.data.price }}</div>
          <div v-else v-tooltip.top="'Средняя цена '">
            <b> {{ slotProps.data.average }}</b>
          </div>
        </template>
      </Column>
      <div v-if="$store.state.curUser">
        <div v-if="!$store.state.curUser.hide_price">
          <Column field="sum_to" header="Сумма ТО, Р">
            <template #body="slotProps">
              <span
                :class="{
                  'font-bold':
                    this.array.indexOf(slotProps.data) ===
                    this.array.length - 1,
                }"
              >
                {{ slotProps.data.sum_to }}
              </span>
            </template>
          </Column>
        </div>
      </div>
      <Column
        field="sum"
        header="Сумма, Р"
        :showFilterMenu="false"
        :sortable="dataProps.sort"
      >
        <template #header>
          <FilterFromTo
            v-model:valueFrom="dataProps.filters.sum.from"
            v-model:valueTo="dataProps.filters.sum.to"
          />
        </template>
        <template #body="slotProps">
          <span
            :class="{
              'font-bold':
                this.array.indexOf(slotProps.data) === this.array.length - 1,
            }"
          >
            {{ slotProps.data.sum }}
          </span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { timeFormater } from "@/handlers/timeFormater";
import store from "@/store";
import config from "@/configs";

import FilterSearch from "@/components/Transactions/Filtres/FilterSearch";
import FilterFromTo from "@/components/Transactions/Filtres/FilterFromTo";
import FiltersSelect from "@/components/Transactions/Filtres/FiltersSelect";

export default {
  name: "TableGroupAll",
  props: {
    dataProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      config: config,
      value: "",
      // filters1: {'card_number': {value: null, matchMode: FilterMatchMode.IN}},
      checked: true,
      array: [],
    };
  },
  components: {
    FilterSearch,
    FilterFromTo,
    FiltersSelect,
  },
  methods: {
    formaterDate(timeValue, hour) {
      return timeFormater(timeValue, hour);
    },
    onPage(event, type) {
      this.$emit("onPage", { event: event, type: type });
    },
    onSort(event) {
      if (event.sortOrder + 1) {
        this.$emit("onSort", { sort: event.sortField, desc: false });
      } else {
        this.$emit("onSort", { sort: event.sortField, desc: true });
      }
    },
    postValue(data) {
      console.log(123);
      // this.$emit("postValue", { value: data.value });
      // data.value > 0 ? (this.checked = false) : (this.checked = true);
    },
    toggle(event) {
      this.$refs.filters.toggle(event);
    },
    rowClass(data) {
      return !data.card_number ? "trn-result-row" : " ";
    },
    getCardDesign(code, subCode, setting) {
      let param = this.config.CARD_DISIGN["DEFAULT"][setting];
      if (code) {
        let disignSettings = this.config.CARD_DISIGN;
        let subDisignSettings = this.config.CARD_DISIGN.PPLUS;
        let arrayKey = [];
        let subArrayKey = [];
        for (let value of Object.keys(disignSettings)) {
          arrayKey.push(value);
        }
        for (let value of Object.keys(subDisignSettings)) {
          subArrayKey.push(value);
        }
        arrayKey.forEach((element) => {
          if (element === code) {
            if (code === "PPLUS") {
              subArrayKey.forEach((el) => {
                if (el == subCode) {
                  param = this.config.CARD_DISIGN[code][subCode][setting];
                }
                // else {
                //   param = this.config.CARD_DISIGN[code]["default"][setting];
                // }
              });
            } else {
              param = this.config.CARD_DISIGN[element][setting];
            }
          }
        });
      }
      return param;
    },
  },
  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
  },
  watch: {
    dataProps: {
      deep: true,
      handler(data) {
        data.trnList !== null
          ? (this.array = data.trnList.concat(data.trnTotal))
          : (this.array = null);
      },
    },
    curCompany() {
      this.dataProps.trnList !== null
        ? (this.array = this.dataProps.trnList.concat(this.dataProps.trnTotal))
        : (this.array = null);
    },
  },
  mounted() {
    this.dataProps.trnList !== null
      ? (this.array = this.dataProps.trnList.concat(this.dataProps.trnTotal))
      : (this.array = null);
  },
};
</script>

<style lang="scss">
</style>