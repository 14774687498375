<template>
  <DataTable
    breakpoint="960px"
    class="shadow-2 p-datatable-sm"
    selectionMode="multiple"
    :value="array"
    :scrollable="true"
    responsiveLayout="stack"
    sortMode="single"
    :rowClass="rowClass"
    @sort="onSort($event)"
    scrollHeight="calc(100vh - 200px)"
  >
    <template #empty> Транзакции не найдены.</template>
    <Column field="company" header="Компания">
      <template #body="slotProps">
        <span v-if="slotProps.data.company">
          {{
            companyList.find((el) =>
              el.id === slotProps.data.company ? el.name : null
            ).name
          }}</span
        >
        <span v-else><b>Общий итог:</b></span>
        <!-- {{
          companies.find((el) =>
            el.id === slotProps.data.company ? el.name : null
          )
            ? companies.find((el) =>
                el.id === slotProps.data.company ? el.name : null
              ).name
            : "Общий итог:"
        }} -->
      </template>
    </Column>
    <Column field="count" :sortable="sort" header="Транзакции">
      <template #body="slotProps">
        <span
          :class="{
            'font-bold':
              this.array.indexOf(slotProps.data) === this.array.length - 1,
          }"
        >
          {{ slotProps.data.count }}
        </span>
      </template>
    </Column>
    <Column field="amount" :sortable="sort" header="Кол-во, л">
      <template #body="slotProps">
        <span
          :class="{
            'font-bold':
              this.array.indexOf(slotProps.data) === this.array.length - 1,
          }"
        >
          {{ slotProps.data.amount }}
        </span>
      </template></Column
    >
    <div v-if="$store.state.curUser">
      <div v-if="!$store.state.curUser.hide_price">
        <Column field="sum_to" header="Сумма ТО, ₽">
          <template #body="slotProps">
            <span
              :class="{
                'font-bold':
                  this.array.indexOf(slotProps.data) === this.array.length - 1,
              }"
            >
              {{ slotProps.data.sum_to }}
            </span>
          </template></Column
        >
      </div>
    </div>
    <Column field="sum" :sortable="sort" header="Сумма, ₽">
      <template #body="slotProps">
        <span
          :class="{
            'font-bold':
              this.array.indexOf(slotProps.data) === this.array.length - 1,
          }"
        >
          {{ slotProps.data.sum }}
        </span>
      </template></Column
    >
  </DataTable>
</template>

<script>
import store from "@/store";

export default {
  name: "TableGroupCompany",
  props: {
    dataProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      array: [],
    };
  },
  methods: {
    onSort(event) {
      if (event.sortOrder + 1) {
        this.$emit("onSort", { sort: event.sortField, desc: false });
      } else {
        this.$emit("onSort", { sort: event.sortField, desc: true });
      }
    },
    rowClass(data) {
      return !data.company ? "trn-result-row" : " ";
    },
  },
  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
    companyList() {
      return store.state.company.companyList;
    },
  },
  watch: {
    dataProps(value) {
      value.trnList !== null
        ? (this.array = value.trnList.concat(value.trnTotal))
        : (this.array = null);
    },
    curCompany() {
      this.dataProps.trnList !== null
        ? (this.array = this.dataProps.trnList.concat(this.dataProps.trnTotal))
        : (this.array = null);
    },
  },
  mounted() {
    this.dataProps.trnList !== null
      ? (this.array = this.dataProps.trnList.concat(this.dataProps.trnTotal))
      : (this.array = null);
  },
};
</script>

<style lang="scss">
</style>