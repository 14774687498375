<template  v-cloak>
  <layout-base>
    <loading-progress :loading="loading"></loading-progress>
    <MobileSpiner :loading="loading" />
    <div
      v-if="dateStartTrn !== null && curCompany"
      class="mt-1 trn-wrapper"
      :class="{ ' overlock': loading }"
    >
      <div class="trn-data-table">
        <div class="table-header p-datatable-header py-2 px-2 bold">
          <div class="title-header-trn">
            Данные указанные в отчете являются справочными
          </div>
          <div
            class="flex sm:align-items-center align-items-start justify-content-end inner-header-trn"
          >
            <SelectCompany
              style="flex: 1 1 280px; width: 280px"
              :show-title="false"
              class="mr-2 sm:mb-0 mb-4"
              :class="{ 'border-red-500': companyListLite.length == 0 }"
              :selected-items="selectedCompany"
              :items="companyListLite"
              :showToggleAll="false"
              v-on:upToParentItems="updateSelectedCompany"
            />
            <SelectGrouping
              v-model="selectedcategory"
              :items="fillterCategory"
              @action="updateTrn"
              placeholder="Выбрать группировку"
              class="mr-2 sm:mb-0 mb-4"
              style="flex: 1 0 280px"
            />
            <div class="sm:mb-0 mb-2 mr-2 sm:w-auto w-full">
              <TheCalendarFromTo v-on:setDate="getDateFromComponent" />
            </div>
            <div style="flex: 1 0 110px">
              <Button
                :disabled="resetDisables"
                @click="resetFiltres"
                v-tooltip.left="'Сбросить все филтры'"
                iconPos="right"
                icon="pi pi-refresh"
                class="p-button-sm mr-2"
              />
              <Button
                :disabled="showBtnsColumns"
                v-tooltip.bottom="'Добавить колонку'"
                type="button"
                icon="pi pi-cog"
                class="p-button-sm"
                @click="toggleColumns"
              />
              <OverlayPanel
                ref="op"
                :showCloseIcon="true"
                :dismissable="true"
                :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
                :style="{ width: '450px' }"
              >
                <MultiSelect
                  class="w-full"
                  v-model="selectedColumns"
                  :options="columns"
                  optionLabel="header"
                  :showToggleAll="false"
                  placeholder="Добавить колонку"
                >
                </MultiSelect>
              </OverlayPanel>
            </div>
          </div>
        </div>
        <ScrollTop :threshold="100" />
        <component
          @onPage="getNumberPage"
          @onSort="getSortDesc"
          :dataProps="tableTools"
          :is="activeGroupTable"
        ></component>
        <!-- v-show="isPaginator" -->
        <Paginator
          :rows="rowsTrn"
          :rowsPerPageOptions="[100, 300, 500, 1000]"
          @page="onPage($event, 'global')"
          :totalRecords="totalRecords"
        ></Paginator>
      </div>
    </div>
  </layout-base>
</template>
<script>
import store from "@/store";
// import { getCompany } from "@/api/company";
import { getTransactions, getHandlersList } from "@/api/trn";
import { timeFormater } from "@/handlers/timeFormater";
import config from "@/configs";
import FilterCompany from "@/components/FilterCompany.vue";
import TableGroupAll from "@/components/Transactions/Tables/TableGroupAll";
import TableGroupCompany from "@/components/Transactions/Tables/TableGroupCompany";
import TableGroupCard from "@/components/Transactions/Tables/TableGroupCard";
import TableGroupCG from "@/components/Transactions/Tables/TableGroupCG";
import TableGroupHolders from "@/components/Transactions/Tables/TableGroupHolders";
import TableGroupServices from "@/components/Transactions/Tables/TableGroupServices";
import SelectGrouping from "@/components/Transactions/SelectGrouping";
import MultiCalendar from "@/components/MultiCalendar";
import SelectCompany from "@/components/SelectCompany";
import TheCalendarFromTo from "../components/TheCalendarFromTo.vue";

export default {
  data() {
    return {
      config: config,
      transactionsList: [],
      transactions: {
        card: [],
        company: [],
        group_by_card: [],
        holder: [],
        service: [],
        all: [],
      },
      loading: true,
      searchString: "",
      active: 0,
      isPaginator: null,
      dateStartTrn: null,
      dateEndTrn: null,
      groupTrn: null,
      companyTrn: this.getDefaultCompany(),
      cardTrn: null,
      pageTrn: 1,
      rowsTrn: 100,
      totalRecords: null,
      selectedColumns: null,
      columns: null,
      products: null,
      timeTrn: [],
      companies: this.companyList,
      cards: [],
      selectedCompany: [],
      displayFilltres: false,
      empty: false,
      expandedRowGroups: null,
      checked: null,
      results: true,
      transactionsListTotal: [],
      filtresLabels: "Фильтры",
      displayDialog: false,
      cardTrnList: [],
      showBtnsColumns: null,
      activeTabFilter: 0,
      selectedcategory: [],
      fillterCategory: [
        { options: "Карты", value: "card" },
        { options: "Компании", value: "company" },
        { options: "Группы карт", value: "group_by_card" },
        { options: "Держатели", value: "holder" },
        { options: "Услуги", value: "service" },
      ],
      sort: "",
      desc: null,
      sortable: true,
      filtres: true,
      resetDisables: false,
      filltres: {
        handlers: [],
        cardNumber: "",
        to_address: "",
        group_to: "",
        contract_num: "",
        holder: "",
        card_group: "",
        service: "",
        to_code: "",
        handler_name: "",
        amount: {
          from: null,
          to: null,
        },
        price: {
          from: null,
          to: null,
        },
        sum: {
          from: null,
          to: null,
        },
      },
      handlersList: [],
      // totalAmount: this.transactionsListTotal[0].amount
    };
  },
  components: {
    TableGroupCard,
    TableGroupCompany,
    TableGroupCG,
    TableGroupHolders,
    TableGroupServices,
    FilterCompany,
    TableGroupAll,
    MultiCalendar,
    SelectCompany,
    SelectGrouping,
    TheCalendarFromTo,
  },
  methods: {
    removeChipCompany(index) {
      this.selectedCompany.splice(index, 1);
      // this.getCompanyTrn();
      this.loadLazyData();
    },
    chipCompanyName(id) {
      return store.state.company.companyList.filter((el) => el.id === id)[0]
        .name;
    },
    getDate(date, type) {
      let start = "T00:00:00";
      let end = "T23:59:59";
      return type === "start" ? date + start : date + end;
    },
    firstRenderDate() {
      this.dateStartTrn = this.dateStartTrn = this.getDate(
        new Date(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1) -
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            ).getTimezoneOffset() *
              60000
        )
          .toISOString()
          .split("T")[0],
        "start"
      );
      this.dateEndTrn = this.getDate(
        new Date().toISOString().split("T")[0],
        "end"
      );
    },
    formatTime(value) {
      return timeFormater(value, false);
    },
    toggle(event) {
      // console.log(event);
      this.$refs.calendar.toggle(event);
    },
    updateSelectedCompany(data) {
      // alert(data.items)
      this.selectedCompany = data.items;
      this.getCompanyTrn();
      this.loadLazyData();
    },
    toggleColumns(event) {
      this.$refs.op.toggle(event);
    },
    toggleFiltres() {
      // this.cardTrn = ''
      this.activeTabFilter || this.empty
        ? (this.activeTabFilter = 0)
        : (this.activeTabFilter = 1);
    },
    loadLazyData() {
      this.loading = true;
      // this.getGroupTrn();
      setTimeout(getTransactions(this), Math.random() * 1000 + 750);
    },
    getSortDesc(data) {
      this.sort = "";
      this.desc = null;
      let sort = data.sort;
      let desc = data.desc;
      this.sort = sort;
      this.desc = desc;
      this.loadLazyData();
    },
    getNumberPage(data) {
      let events = data.event;
      let type = data.type;
      this.onPage(events, type);
    },
    onPage(event, type) {
      if (type === "global") {
        // console.log(event);
        this.pageTrn = event.page + 1;
        this.rowsTrn = event.rows;
        this.loadLazyData();
      } else if (type === "local") {
        this.pageTrn = event.page + 1;
        this.rowsTrn = event.rows;
        this.OpenDialog(event.page + 1, type);
      }
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    getCompanyTrn() {
      console.log(this.selectedCompany.length);
      console.log(this.companyList.length);
      this.companyTrn =
        this.selectedCompany.length == this.companyList.length
          ? ["0"]
          : this.selectedCompany;
    },
    getCardTrn(event) {
      this.cardTrn = this.selectedCard.map((el) => el.number).join("");
    },
    showFiltres() {
      this.displayFilltres
        ? (this.displayFilltres = false)
        : (this.displayFilltres = true);
    },
    zeroingFiltres() {
      this.timeTrn = [];
      this.timeTrn[0] = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );
      this.timeTrn[1] = new Date();
      // this.dateEndTrn = this.getDate(
      //     new Date().toISOString().split("T")[0],
      //     "end"
      // );
      // this.setDateTrn();
      this.selectedCompany = [];
      this.transactionsListTotal = [];
      this.companyTrn = store.state.company.curCompany
        ? store.state.company.curCompany.id
        : null;
      store.state.company.curCompany
        ? this.selectedCompany.push(store.state.company.curCompany.id)
        : null;
      this.selectedcategory = [];
      this.selectedcategory = [];
      // this.loadLazyData();
    },

    getDefaultCompany() {
      return store.state.company.curCompany
        ? store.state.company.curCompany.id
        : null;
    },
    cardGroup() {
      return this.checked ? "card_number" : null;
    },
    // getGroupTrn() {
    //   console.log(this.selectedcategory);
    //   this.groupTrn = this.selectedcategory[0]
    //   // this.selectedcategory ? this.groupTrn = this.selectedcategory[0].value : this.groupTrn = null
    //   // if (this.selectedcategory != 0) {
    //   //   // this.groupTrn = this.selectedcategory[0].value
    //   //   this.selectedcategory.forEach((el) => {
    //   //     this.groupTrn = el.value;
    //   //   });
    //   // } else {
    //   //   this.groupTrn = null;
    //   // }
    // },
    postCardFilter(data) {
      this.filltres.cardNumber = data.value;
      // this.loadLazyData()
    },
    iterateObject(obj) {
      for (let key in obj) {
        let value = obj[key];
        if (typeof value == "object") {
          // работаем рекурсивно
          this.iterateObject(value);
        } else {
          obj[key] = null;
        }
      }
    },
    resetFiltres() {
      console.log(this.filltres);
      this.iterateObject(this.filltres);
      this.sort = "";
      this.desc = null;
      this.sortable = null;
      this.zeroingFiltres();
      setTimeout(() => {
        this.sortable = true;
        this.loadLazyData();
      }, 150);
    },
    getDateFromComponent(data) {
      this.dateStartTrn = data.start;
      this.dateEndTrn = data.end;
      this.loadLazyData();
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.loading = true;
    this.getDefaultCompany();
    getHandlersList(this);
    store.state.company.curCompany
      ? this.selectedCompany.push(store.state.company.curCompany.id)
      : null;
    if (store.state.company.curCompany) {
      this.loading = true;
      this.firstRenderDate();
      // getCompany(this);
      this.loadLazyData();
    }
  },
  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
    companyList() {
      return store.state.company.companyList;
    },
    companyListLite() {
      return store.state.company.companyList.map(function (el) {
        return {
          id: el.id,
          name: el.name,
        };
      });
    },
    activeGroupTable() {
      let key =
        this.selectedcategory.length !== 0
          ? this.selectedcategory[0]["value"]
          : "all";
      switch (key) {
        case "card":
          return "table-group-card";
        case "company":
          return "table-group-company";
        case "group_by_card":
          return "table-group-c-g";
        case "service":
          return "table-group-services";
        case "holder":
          return "table-group-holders";
        case "all":
          return "table-group-all";
      }
    },
    groupTrnList() {
      let key =
        this.selectedcategory.length !== 0
          ? this.selectedcategory[0]["value"]
          : "all";
      switch (key) {
        case "card":
          return "table-group-card";
        case "company":
          return "table-group-company";
        case "group_by_card":
          return "table-group-c-g";
        case "service":
          return "table-group-services";
        case "holder":
          return "table-group-holders";
        case "all":
          return "table-group-all";
      }
    },
    tableTools() {
      return {
        trnList:
          this.transactions[
            this.selectedcategory.length !== 0
              ? this.selectedcategory[0]["value"]
              : "all"
          ],
        trnTotal: this.transactionsListTotal,
        results: this.results,
        dateStart: this.dateStartTrn,
        dateEnd: this.dateEndTrn,
        companies: this.companies,
        rows: this.rowsTrn,
        total: this.totalRecords,
        isPaginator: false,
        loading: this.loading,
        columns: this.selectedColumns,
        sort: this.sortable,
        filters: this.filltres,
        handlersList: this.handlersList,
      };
    },
    curUserRole() {
      return store.state.curUser.role;
    },
  },
  watch: {
    empty(value) {
      if (value) {
        this.$toast.add({
          severity: "warn",
          summary: `Транзакции не найдены`,
          detail: "Попробуйте поменять критерии поиска",
          life: 3000,
        });
      }
    },
    cardTrn(value) {
      value ? this.loadLazyData() : "";
      value.length === 0 ? this.loadLazyData() : "";
    },
    filltres: {
      deep: true,
      handler(data) {
        console.log(data);
        if (
          data.cardNumber !== "" ||
          data.to_address !== "" ||
          data.group_to !== "" ||
          data.contract_num !== "" ||
          data.to_code !== "" ||
          data.service !== "" ||
          data.holder !== "" ||
          data.card_group !== "" ||
          data.handler_name !== "" ||
          data.amount.from !== null ||
          data.amount.to !== null ||
          data.price.from !== null ||
          data.price.to !== null ||
          data.sum.from !== null ||
          data.sum.to !== null ||
          data.handlers != null
        ) {
          this.loadLazyData();
        }
      },
    },
    curCompany(value) {
      this.selectedCompany = [];
      this.selectedCompany[0] = store.state.company.curCompany.id;
      this.companyTrn = store.state.company.curCompany
        ? store.state.company.curCompany.id
        : "";
      this.firstRenderDate();
      // getCompany(this);
      this.loadLazyData();
      // this.loading = true;
      // setTimeout(() => {
      //   this.loading = false;
      // }, 3500);
    },
    groupTrn(value) {
      if (value !== null) {
        this.showBtnsColumns = true;
      } else {
        this.showBtnsColumns = false;
      }
    },
    selectedcategory(value) {
      if (value.length !== 0) {
        this.groupTrn = value[0].value;
      } else {
        this.groupTrn = null;
      }
      this.loadLazyData();
    },
    // dateStartTrn() {
    //   this.dateEndTrn ? this.loadLazyData() : "";
    // },
    // dateEndTrn() {
    //   this.loadLazyData();
    // },
  },
  created() {
    document.title = "Транзакции | " + window.appBaseTitle;
    this.columns = [
      {
        field: "contract_num",
        header: "Договор",
        filters: `filter-search`,
        placeholder: "Номер договора",
      },
      { field: "contract_date", header: "Дата договора" }, 
      {
        field: "group_to",
        header: "Поставщик",
        filters: `filter-search`,
        placeholder: "Название ТО",
      },
      {
        field: "to_code",
        header: "ТО",
        filters: `filter-search`,
        placeholder: "Код ТО",
      },
      // { field: "price_to", header: "Цена ТО, ₽" },
    ];

    this.selectedColumns = "";
    !this.timeTrn.length ? this.timeTrn.push(new Date()) : [];
    // this.getDefaultCompany();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr:not(.p-highlight):not(.blns-result-row):hover {
    background-color: #5a92f95e !important;
  }

  .p-datatable .p-datatable-tbody > tr:focus,
  .p-datatable .p-datatable-tbody > tr.p-highlight,
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    outline: none !important;
    background-color: #5a92f95e !important;
  }
  .p-dialog-mask.p-component-overlay.p-component-overlay-enter {
    z-index: 10002 !important;
  }
}
.overlock {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 10001;
  }
}
</style>