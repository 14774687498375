<template>
  <MultiSelect
    v-model="valueG"
    :options="items"
    :filter="true"
    optionLabel="options"
    :placeholder="placeholder"
    @change="change($event)"
    @input="change"
    :selectAll="false"
    :selectionLimit="10"
    emptyFilterMessage="Нет совпадений"
    display="chip"
    ref="select"
  >
    <template #footer>
      <div class="p-multiselect-footer bg-gray-300">
        <div
          class="flex align-items-center justify-content-end"
          style="padding: 0.75rem 1.25rem"
        >
          <Button
            @click="this.$refs.select.hide()"
            class="p-button-sm"
            label="Закрыть"
          />
        </div>
      </div>
    </template>
  </MultiSelect>
</template>

<script>
export default {
  name: "SelectGrouping",
  props: {
    modelValue: Array,
    placeholder: String,
    items: {
      type: Array,
      requaired: true,
    },
  },
  data() {
    return {
      valueG: this.modelValue,
    };
  },
  methods: {
    change(event) {
      console.log(event.value.length > 0);
      if (event.value.length > 0) {
        this.valueG = [];
        this.valueG.push(event.value[event.value.length - 1]);
        this.$emit("update:modelValue", this.valueG);
      } else {
        this.$emit("update:modelValue", []);
      }
    },
  },
};
</script>

<style>
</style>