<template>
  <div class="card-table" v-if="dataProps.sort">
    <DataTable
      stripedRows
      showGridlines
      selectionMode="multiple"
      breakpoint="960px"
      class="shadow-2 p-datatable-sm"
      :value="array"
      :lazy="true"
      :scrollable="true"
      responsiveLayout="stack"
      :loading="dataProps.loading"
      :paginator="dataProps.isPaginator"
      @page="onPageCards($event, 'global')"
      :rows="dataProps.rows"
      :totalRecords="dataProps.total"
      v-model:filters="filters1"
      filterDisplay="menu"
      resizableColumns="true"
      columnResizeMode="fit"
      :rowClass="rowClass"
      scrollHeight="calc(100vh - 200px)"
    >
      <template #empty> Транзакции не найдены.</template>
      <!--    <template #header>-->
      <!--      <div class="table-header">-->
      <!--        Данные указанные в отчете являются справочными-->
      <!--      </div>-->
      <!--    </template>-->
      <Column
        field="card_number"
        :showFilterMenu="false"
        style="min-width: 14rem"
      >
        <template #header>
          <span>Номер карты</span>
          <!-- <FilterSearch
                v-on:getValue="postValue"
                :valueTrn="dataProps.filterValueCard"
                :placeholderName="'Номер карты'"
              /> -->
        </template>

        <template #body="slotProps">
          <Button
            v-show="slotProps.data.card_number"
            class="p-button-sm"
            @click="OpenDialog($event, slotProps.data.card_number)"
            :label="slotProps.data.card_number"
          />
          <span v-show="!slotProps.data.card_number"><b>Общий итог:</b></span>
        </template>
      </Column>
      <Column field="count" header="Транзакции">
        <template #body="slotProps">
          <span
            :class="{
              'font-bold':
                this.array.indexOf(slotProps.data) === this.array.length - 1,
            }"
          >
            {{ slotProps.data.count }}
          </span>
        </template></Column
      >
      <Column field="amount" header="Кол-во, л">
        <template #body="slotProps">
          <span
            :class="{
              'font-bold':
                this.array.indexOf(slotProps.data) === this.array.length - 1,
            }"
          >
            {{ slotProps.data.amount }}
          </span>
        </template></Column
      >
      <div v-if="$store.state.curUser">
        <div v-if="!$store.state.curUser.hide_price">
          <Column field="sum_to" header="Сумма ТО, ₽">
            <template #body="slotProps">
              <span
                :class="{
                  'font-bold':
                    this.array.indexOf(slotProps.data) ===
                    this.array.length - 1,
                }"
              >
                {{ slotProps.data.sum_to }}
              </span>
            </template></Column
          >
        </div>
      </div>
      <Column field="sum" header="Сумма, ₽">
        <template #body="slotProps">
          <span
            :class="{
              'font-bold':
                this.array.indexOf(slotProps.data) === this.array.length - 1,
            }"
          >
            {{ slotProps.data.sum }}
          </span>
        </template></Column
      >
      <!--      <template #footer>-->
      <!--        <div-->
      <!--            v-if="results && trnTotal.length != 0"-->
      <!--            class="results-wrapper flex justify-content-end w-full"-->
      <!--        >-->
      <!--          <DataTable-->
      <!--              class="w-full p-datatable-sm"-->
      <!--              :value="trnTotal"-->
      <!--              responsiveLayout="scroll"-->
      <!--              resizableColumns="true"-->
      <!--              columnResizeMode="fit"-->
      <!--          >-->
      <!--            <Column field="county" header="Общий итог"></Column>-->
      <!--            <Column field="count" header=""></Column>-->
      <!--            <Column field="amount" header=""></Column>-->
      <!--            <div v-if="$store.state.curUser">-->
      <!--              <div v-if="!$store.state.curUser.hide_price">-->
      <!--                <Column field="sum_to" header="Сумма ТО, ₽"></Column>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <Column field="sum" header=""></Column>-->
      <!--          </DataTable>-->
      <!--        </div>-->
      <!--      </template>-->
    </DataTable>
    <Dialog
      v-model:visible="displayDialog"
      maximizable="true"
      breakpoints="100%"
      modal="true"
      @hide="this.cardTrn = null"
      contentStyle="width: 100vw; height: 100vh"
    >
      <template #header>
        <h3 class="mb-2">
          Транзакции по карте <i>{{ cardExpand }}</i>
        </h3>
      </template>
      <DataTable
        class="shadow-2 p-datatable-sm"
        stripedRows
        :resizableColumns="true"
        columnResizeMode="fit"
        :value="cardTrnListExpand"
        ref="dt"
        :lazy="true"
        :paginator="isPaginatorExpand"
        :rows="rowsExpand"
        :totalRecords="totalRecordsExpand"
        :loading="loadingExpand"
        @page="onPage($event, 'local')"
        responsiveLayout="stack"
        :scrollable="true"
        sortMode="single"
        :sortOrder="1"
      >
        <template #empty> Транзакции не найдены.</template>
        <template #loading>
          <b style="color: #fff"
            >Загрузка данных о транзакциях. Пожалуйста подождите.</b
          >
        </template>
        <template #header>
          <div class="table-header">
            Данные указанные в отчете являются справочными
          </div>
        </template>
        <Column field="card_number" header="Номер карты"> </Column>
        <Column field="date" header="Дата">
          <template #body="slotProps">
            <div v-tooltip.bottom="formaterDate(slotProps.data.date)">
              {{
                slotProps.data.date ? formaterDate(slotProps.data.date) : "-"
              }}
            </div>
          </template>
        </Column>
        <Column field="company_name" header="Компания">
          <template #body="slotProps">
            <div title="slotProps.data.company_name">
              {{ slotProps.data.company_name }}
            </div>
          </template>
        </Column>
        <Column field="group_to" header="Поставщик">
          <template #body="slotProps">
            <div class="mr-1" :title="slotProps.data.group_to">
              {{ slotProps.data.group_to }}
            </div>
            <div style="" fitContent="false">
              <span
                style="text-align: center"
                class="pi pi-compass"
                v-tooltip.bottom="
                  slotProps.data.to_address
                    ? slotProps.data.to_address
                    : 'no adress'
                "
              ></span>
            </div>
          </template>
        </Column>
        <Column
          v-for="(col, index) of dataProps.selectedColumns"
          :field="col.field"
          :header="col.header"
          :key="col.field + '_' + index"
        ></Column>
        <Column field="service_name" header="Услуга"></Column>
        <Column field="amount" header="Кол-во, л"></Column>
        <Column field="price" header="Цена, ₽"></Column>
        <div v-if="$store.state.curUser">
          <div v-if="!$store.state.curUser.hide_price">
            <Column field="price_to" header="Цена ТО, ₽"></Column>
          </div>
        </div>
        <div v-if="$store.state.curUser">
          <div v-if="!$store.state.curUser.hide_price">
            <Column field="sum_to" header="Сумма ТО, ₽"></Column>
          </div>
        </div>
        <Column field="sum" header="Сумма, ₽"></Column>
      </DataTable>
    </Dialog>
  </div>
</template>

<script>
import store from "@/store";
import { timeFormater } from "@/handlers/timeFormater";
import { getTransactionCurrentCard } from "@/api/trn";
import { FilterMatchMode } from "primevue/api";
import FilterSearch from "@/components/Transactions/Filtres/FilterSearch";

export default {
  name: "TableGroupCard",
  props: {
    dataProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      displayDialog: false,
      cardTrnList: null,
      rowsTrn: 10,
      cardTrn: null,
      rowsExpand: 25,
      cardExpand: null,
      pageExpand: 1,
      cardTrnListExpand: null,
      totalRecordsExpand: null,
      loadingExpand: false,
      dateStartExpand: this.dataProps.dateStart,
      dateEndExpand: this.dataProps.dateEnd,
      isPaginatorExpand: true,
      filters1: { card_number: { value: null, matchMode: FilterMatchMode.IN } },
      checked: true,
      array: [],
    };
  },
  components: {
    FilterSearch,
  },
  methods: {
    formaterDate(timeValue, hour) {
      return timeFormater(timeValue, hour);
    },
    onPage(event, type) {
      if (type === "global") {
        this.$emit("onPage", { event: event, type: type });
      } else if (type === "local") {
        this.loadingExpand = true;
        this.pageExpand = event.page + 1;
        this.rowsExpand = event.rows;
        // console.log(event.page)
        getTransactionCurrentCard(this);
        // this.OpenDialog(event.page + 1);
      }
    },
    onPageCards(event, type) {
      this.$emit("onPage", { event: event, type: type });
    },
    OpenDialog(event, cardNum) {
      this.pageExpand = 1;
      this.cardExpand = null;
      this.cardExpand = cardNum;
      this.displayDialog = true;
      (this.loadingExpand = true), getTransactionCurrentCard(this);
    },
    postValue(data) {
      this.$emit("postValue", { value: data.value });
      this.toggle();
      data.value > 0 ? (this.checked = false) : (this.checked = true);
    },
    toggle(event) {
      this.$refs.filters.toggle(event);
    },
    rowClass(data) {
      return !data.card_number ? "trn-result-row" : " ";
    },
  },
  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
  },
  watch: {
    dataProps(value) {
      value.trnList !== null
        ? (this.array = value.trnList.concat(value.trnTotal))
        : (this.array = null);
    },
    curCompany() {
      this.dataProps.trnList !== null
        ? (this.array = this.dataProps.trnList.concat(this.dataProps.trnTotal))
        : (this.array = null);
    },
  },
  mounted() {
    this.dataProps.trnList !== null
      ? (this.array = this.dataProps.trnList.concat(this.dataProps.trnTotal))
      : (this.array = null);
  },
};
</script>

<style lang="scss">
</style>