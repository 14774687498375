<template>
  <div style="width: 16px" class="mr-1">
    <Button
      type="button"
      style="width: 16px; overflow: visible"
      icon="pi pi-filter"
      :class="{
        'fil-btn': modelValue.length != 0,
      }"
      class="p-button-sm p-button-text p-0"
      @click="toggle"
    />
    <OverlayPanel
      class="select-fil"
      ref="filters"
      :showCloseIcon="true"
      ariaCloseLabel=""
    >
      <div>
        <Listbox
          filter
          v-model="selected"
          :options="items"
          optionLabel="full_name"
          optionValue="code"
          class="w-full pt-0"
          listStyle="height:250px"
          multiple
        />
        <Button
          class="mt-4 mx-auto p-1 block w-10 p-button-text underline"
          @click="selected = items.map((el) => el.code)"
          label="Выбрать все"
        />
        <Button
          class="mx-auto p-1 block w-10 p-button-text underline"
          :disabled="!selected.length"
          @click="(selected = [])"
          label="Сбросить"
        />
        <Button
          class="mt-4 mx-auto block w-10"
          @click="showValue"
          label="Пременить"
        />
      </div>
    </OverlayPanel>
  </div>
</template>
    
    <script>
export default {
  name: "FiltersSelect",
  props: {
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: this.modelValue,
    };
  },
  methods: {
    isSearcDisabled() {
      if (this.selected) {
        this.isSearcDisables = false;
      } else {
        this.isSearcDisables = true;
        this.$emit("update:modelValue", null);
      }
    },
    showValue() {
      this.$emit("update:modelValue", this.selected);
      this.toggle();
    },
    toggle(event) {
      this.$refs.filters.toggle(event);
    },
  },
  created() {
    this.selected
      ? (this.isSearcDisables = false)
      : (this.isSearcDisables = true);
  },
  computed: {
    //   modelValue: {
    //   get () { return this.modelValue },
    //   set (value) { this.$emit('update:modelValue', value) },
    // }
  },
  watch: {},
};
</script>
    
    <style lang="scss">
.select-fil {
  .p-overlaypanel-content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}
</style>