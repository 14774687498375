<template>
  <DataTable
    breakpoint="960px"
    class="shadow-2 p-datatable-sm"
    selectionMode="multiple"
    :value="array"
    :scrollable="true"
    responsiveLayout="stack"
    sortMode="single"
    :rowClass="rowClass"
    @sort="onSort($event)"
    scrollHeight="calc(100vh - 200px)"
  >
    <template #empty> Транзакции не найдены.</template>
    <Column field="card__holder__name" header="Держатель">
      <template #body="slotProps">
        <span
          v-if="!(this.array.indexOf(slotProps.data) === this.array.length - 1)"
          :class="!slotProps.data.card__holder__name ? 'font-italic' : ''"
        >
          {{
            slotProps.data.card__holder__name
              ? slotProps.data.card__holder__name
              : "Без держателя"
          }}
        </span>
        <span v-else> <b>Общий итог:</b> </span>
      </template>
    </Column>
    <Column field="count" header="Транзакции"> <template #body="slotProps">
          <span
            :class="{
              'font-bold':
                this.array.indexOf(slotProps.data) === this.array.length - 1,
            }"
          >
            {{ slotProps.data.count }}
          </span>
        </template></Column>
    <Column field="amount" header="Кол-во, л"> <template #body="slotProps">
          <span
            :class="{
              'font-bold':
                this.array.indexOf(slotProps.data) === this.array.length - 1,
            }"
          >
            {{ slotProps.data.amount }}
          </span>
        </template> </Column>
    <div v-if="$store.state.curUser">
      <div v-if="!$store.state.curUser.hide_price">
        <Column field="sum_to" header="Сумма ТО, ₽"> 
          <template #body="slotProps">
          <span
            :class="{
              'font-bold':
                this.array.indexOf(slotProps.data) === this.array.length - 1,
            }"
          >
            {{ slotProps.data.sum_to }}
          </span>
        </template></Column>
      </div>
    </div>
    <Column field="sum" header="Сумма, ₽">  <template #body="slotProps">
          <span
            :class="{
              'font-bold':
                this.array.indexOf(slotProps.data) === this.array.length - 1,
            }"
          >
            {{ slotProps.data.sum }}
          </span>
        </template></Column>
  </DataTable>
</template>

<script>
import store from "@/store";

export default {
  name: "TableGroupHolders",
  props: {
    dataProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      array: [],
    };
  },
  methods: {
    // onSort(event) {
    //   if (event.sortOrder + 1) {
    //     this.$emit("onSort", { sort: event.sortField, desc: false });
    //   } else {
    //     this.$emit("onSort", { sort: event.sortField, desc: true });
    //   }
    // },
    rowClass(data) {
      return this.array.indexOf(data) === this.array.length - 1
        ? "trn-result-row"
        : " ";
    },
  },
  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
  },
  watch: {
    dataProps(value) {
      value.trnList !== null
        ? (this.array = value.trnList.concat(value.trnTotal))
        : (this.array = null);
    },
    curCompany() {
      this.dataProps.trnList !== null
        ? (this.array = this.dataProps.trnList.concat(this.dataProps.trnTotal))
        : (this.array = null);
    },
  },
  mounted() {
    this.dataProps.trnList !== null
      ? (this.array = this.dataProps.trnList.concat(this.dataProps.trnTotal))
      : (this.array = null);
  },
};
</script>

<style lang="scss">
</style>